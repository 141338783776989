<template>
  <el-dialog class="main-container" :title="$t('text.add')" :visible="visible" width="800px" :close-on-click-modal="false" :close-on-press-escape="false" @close="handleDialogClose">
    <alter-form :customeButton="true" ref="alterForm" :labelWidth="'150px'" @formSubmit="formSubmit" :modifyData="modifyData" :formData="formData" :collapseLength="collapseLength">
      <template v-slot:customeButton="data">
        <el-button @click="handleDialogClose">{{ $t('button.cancel') }}</el-button>
        <el-button type="primary" @click="formSubmit(data)">{{ $t('button.confirm') }}</el-button>
      </template>
    </alter-form>
  </el-dialog>
</template>

<script>
import alterForm from '@/components/alterForm'
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'
import * as membersApi from '@/services/members.js'

export default {
  name: 'addAddressDialog',
  components: { alterForm },
  props: {
    visible: {
      type: Boolean,
      default: () => false
    },
    setVisible: {
      type: Function,
      default: () => false
    },
    user: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData,
      addressTypeOptions: [
        {
          label: this.$t('form.members.private'),
          value: 'private'
        },
        {
          label: this.$t('form.members.business'),
          value: 'business'
        }
      ]
    }
  },
  watch: {
    visible (newV, oldV) {
      const _this = this
      if (newV) {
        _this.init()
      }
    }
  },
  created () {

  },
  mounted () {},
  methods: {
    init () {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      this.formData[0].createDateItem[9].options = this.addressTypeOptions
      membersApi.getAddresTypesSimpleList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[8].options = res.data.address_types
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleChange (data, prop) {
    },
    formSubmit (data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data.data))
      obj.user_id = this.user.id
      const params = {
        address: obj
      }
      membersApi.adderssAdd(params).then(res => {
        if (res.status >= 200 && res.status < 300) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$emit('onSubmitClick', true)
            this.$refs.alterForm.resetForm()
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    handleDialogClose () {
      const _this = this
      _this.setVisible(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.main-container {
  .interval-mb {
    margin-bottom: 12px;
  }
  .product-box {
    display: flex;
    align-items: center;
    .cover {
      flex-shrink: 0;
      width: 30%;
      margin-right: 12px;
      .img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .info {
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      .title {
        margin-bottom: 12px;
        word-break: break-all;
      }
    }
  }
}
</style>
