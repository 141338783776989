<template>
  <div class="pcaap-container">
    <div>
      <el-row class="interval">
        <el-col :span="24">
          <el-button type="primary" @click="handleAddAddressClick">{{ $t('form.members.addAddress') }}</el-button>
        </el-col>
      </el-row>
      <el-row class="interval">
        <el-col>
          <el-button type="primary" :disabled="mainTable.multipleSelection.length < 1" @click="handleBatchDestroyClick(-1)">{{
            $t('text.batchDestroy')
          }}</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-table
            ref="mainTable"
            :data="mainTable.data"
            v-loading="mainTable.loading"
            style="width: 100%"
            max-height="700"
            @selection-change="handleMainTableSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="ID" prop="id.value"></el-table-column>
            <el-table-column :label="$t('form.members.user')" prop="user.value" width="150"></el-table-column>
            <el-table-column :label="$t('form.members.addressee')" prop="name.value"></el-table-column>
            <el-table-column :label="$t('form.members.phoneNumber')" prop="phone_number.value" width="110"></el-table-column>
            <el-table-column :label="$t('form.members.roadName')" prop="address.value" width="100"></el-table-column>
            <el-table-column :label="$t('form.members.postalCode')" prop="postal_code.value" width="100"></el-table-column>
            <el-table-column :label="$t('form.members.unitNo')" prop="unit_no.value"></el-table-column>
            <el-table-column :label="$t('form.members.buildingName')" prop="building_name.value" width="120"></el-table-column>
            <el-table-column :label="$t('form.members.defaultAddress')" prop="current.value" width="120">
              <template slot-scope="scope">
                <span :class="scope.row.current.value ? 'true' : 'false'">
                {{getFormateData(scope.row.current.value)}}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('table.common.operate')" fixed="right" width="220">
              <template slot-scope="scope">
                <el-button class="margintop10" size="mini" type="danger" @click="() => handleBatchDestroyClick(scope.row.id)">{{ $t('button.delete') }}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleMainTableSizeChange"
            @current-change="handleMainTableCurrentChange"
            :page-sizes="[50, 100, 150, 200]"
            :page-size="mainTable.pagination.papeSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="mainTable.pagination.totalCount"
          >
          </el-pagination>
        </el-col>
      </el-row>
      <el-row class="interval">
        <el-col :span="24">
          <el-button type="primary" :disabled="activeStep <= 0" @click="handleMainOkClick">{{ $t('button.confirm') }}</el-button>
        </el-col>
      </el-row>
    </div>
    <s-address-list-dialog
      :visible="dialog.addressList.visible"
      :setVisible="setAddressListDialogVisible"
      :user="routeParams"
      @onSubmitClick="handleAddressListDialogSubmitClick"
    />
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import dataDictionary from '@/utils/data-dictionary.js'
import SAddressListDialog from './components/addressDialog/index.vue'
import * as membersApi from '@/services/members.js'
export default {
  components: {
    SAddressListDialog
  },
  props: ['activeStep', 'setActiveStep'],
  data () {
    return {
      dataDictionary,
      routeParams: null,
      selectedAddressId: null,
      mainTable: {
        loading: false,
        // 分页
        pagination: {
          papeSize: 50,
          current: 1,
          totalCount: 0
        },
        multipleSelection: [],
        // 数据
        data: []
      },
      mainForm: {
        data: {
          online: false, // 上架
          homepage_display: false, // 是否首页展示
          name: '', // 活动动名
          name_en: '', // 活动名(英文)
          date: null,
          begin_date: null, // 开始时间
          end_date: null, // 结束时间
          remote_image_url: null
        }
      },
      dialog: {
        addressList: {
          visible: false
        },
        productInfo: {
          type: 0, // 0 新增 1 编辑
          visible: false,
          data: null,
          stock: []
        }
      }
    }
  },
  created () {
    const _this = this
    _this.routeParams = _this.$route.params
    console.log('------------->', this.routeParams)
  },
  mounted () {
    this.requestMainTableData()
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    setMainTableLoading (show) {
      const _this = this
      show ? _this.mainTable.loading = true : _this.mainTable.loading = false
    },
    setProductInfoDialogVisible (show) {
      const _this = this
      _this.dialog.productInfo.visible = show
    },
    setAddressListDialogVisible (show) {
      const _this = this
      _this.dialog.addressList.visible = show
    },
    requestMainTableData () {
      const _this = this
      _this.setMainTableLoading(true)
      const params = {
        user_id_eq: _this.routeParams.id,
        page: _this.mainTable.pagination.current,
        per_page: _this.mainTable.pagination.papeSize
      }
      return membersApi.getMemberAddressList(params).then(res => {
        _this.setMainTableLoading(false)
        const resData = res.data
        _this.mainTable.data = []
        _this.mainTable.pagination.totalCount = resData.pagination.total_count
        _this.$nextTick(() => {
          _this.mainTable.data = resData.objects
        })
      }).catch(() => _this.setMainTableLoading(false))
    },
    handleAddressListDialogSubmitClick (result) {
      const _this = this
      if (result) {
        _this.setAddressListDialogVisible(false)
        _this.requestMainTableData()
      }
    },
    handleAddAddressClick () {
      const _this = this
      _this.dialog.addressList.visible = true
    },
    handleAddProductCancel () {
      const _this = this
      _this.selectedAddressId = null
    },
    handleMainTableSizeChange (val) {
      const _this = this
      _this.mainTable.pagination.current = 1
      _this.mainTable.pagination.totalCount = 0
      _this.mainTable.pagination.papeSize = val
      _this.requestMainTableData()
    },
    handleMainTableCurrentChange (val) {
      const _this = this
      _this.mainTable.pagination.current = val
      _this.requestMainTableData()
    },
    handleMainOkClick () {
      const _this = this
      _this.$router.replace({
        name: 'memberList'
      })
    },
    handleMainTableSelectionChange (val) {
      const _this = this
      _this.mainTable.multipleSelection = val
    },
    handleBatchDestroyClick (data = -1) {
      const _this = this
      const params = { ids: [] }
      let tip = _this.$t('text.confirmBatchDestroy')
      if (data === -1) {
        params.ids = _this.mainTable.multipleSelection.map(item => item.id.value)
      } else {
        params.ids.push(data.value)
        tip = _this.$t('text.confirmDeleteThisItem')
      }
      _this.$confirm(tip, _this.$t('text.confirmation'), {
        type: 'warning'
      }).then(() => {
        _this.setGlobalLoading(true)
        membersApi.deleteMemberAddress(params).then(() => {
          _this.setGlobalLoading(false)
          _this.$message({
            type: 'success',
            message: _this.$t('message.operateSuccess')
          })
          _this.requestMainTableData()
        }).catch(() => {
          _this.setGlobalLoading(false)
        })
      }).catch(err => err)
    },
    getFormateData (data) {
      if (data === true) return this.$i18n.locale === 'zh' ? '是' : 'Yes'
      else if (data === false) return this.$i18n.locale === 'zh' ? '否' : 'No'
      else return data
    }
  }
}
</script>

<style lang="scss" scoped>
.pcaap-container {
  .interval {
    margin-bottom: 12px;
  }
  .product-box {
    display: flex;
    align-items: center;
    .cover {
      flex-shrink: 0;
      width: 40%;
      margin-right: 12px;
      .img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    .info {
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      .title {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: bold;
        word-break: break-all;
      }
    }
  }
  .true {
    background-color: #179a16;
    color: #FFFFFF;
    padding: 5px 8px;
  }
  .false {
    background-color: #db6363;
    color: #FFFFFF;
    padding: 5px 8px;
  }
}
</style>
